.row {
  padding: 90px 0 10px 0;
  max-width: 1200px;
}

.custom_marquee {
  margin-top: 30px;
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  color: var(--primaryitemcolor);
}

.text__div {
  position: relative;
  top: 10%;
  transform: translateY(-50%);
  padding-right: 10%;
  color: var(--textcolor);
}

.text__div h1 {
  font-weight: 500;
  font-size: 48px;
  margin-bottom: 15px;
  margin-top: 50px;
}

.text__div h1 span {
  color: var(--primaryitemcolor);
}

.text__div p {
  font-size: 20px;
  margin-bottom: 20px;
}

.food__image {
  margin-top: 30px;
  display: block;
  margin-left: auto;
  width: 87%;
  border-radius: 550px;
}

.number__properties {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.number__properties .number__properties__digit {
  font-size: 32px;
  font-weight: 700;
}

.number__properties .number__properties__text {
  font-size: 18px;
  font-weight: 600;
  color: var(--secondarytextcolor);
}

.order_button {
  color: var(--light);
  text-decoration: none;
}

.order_button:hover {
  color: var(--light);
}

@media only screen and (min-width: 1400px) and (min-height: 900px) {
  .row {
    padding: 150px 0;
  }

  .number__properties {
    padding: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .text__div {
    position: static;
    transform: translateY(0%);
    text-align: left;
    padding-right: 0;
  }

  .food__image {
    margin: auto;
    width: 70%;
  }
}

@media only screen and (max-width: 576px) {
  .text__div {
    padding: 0 2% 0 2%;
  }

  .text__div h1 {
    font-size: 36px;
    margin-top: -50px;
  }

  .food__image {
    margin-top: 20px;
  }

  .number__properties {
    display: block;
  }
}
