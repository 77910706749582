.items {
  padding: 0px 20px 20px 20px;
}

.items .item_group {
  max-height: 300px;
  overflow: auto;
}

.extra_cond {
  font-size: 12px;
  font-weight: 400;
  color: var(--primaryitemcolor);
}

.buttons {
  text-align: right;
}

.buttons .btn_style {
  background-color: var(--light);
  border: 1px solid var(--primaryitemcolor);
  color: var(--primaryitemcolor);
  transition: 0.3s;
}

.buttons .btn_style2 {
  border: 1px solid var(--primaryitemcolor);
}

.buttons .btn_style:hover {
  background-color: var(--primaryitemcolor);
  color: var(--light);
}

.amount {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.amount p {
  font-size: 24px;
  font-weight: 700;
}

@media only screen and (max-width: 576px) {
  .amount p {
    font-size: 22px;
    font-weight: 700;
  }
}
